var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        "mask-closable": true,
        visible: _vm.visible,
        title: "补缴详情",
        size: "large",
      },
      on: { ok: _vm.cancel, cancel: _vm.cancel },
    },
    [
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          pagination: false,
          scroll: { x: 1500 },
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "staff-id": scope.record.staffId,
                    "on-job-id": scope.record.onJobId,
                    "table-name": scope.record.staffName,
                    title: scope.record.staffName,
                    "is-archives": true,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }